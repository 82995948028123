/**
 * @file
 *
 * this file contains the data fetching code for connections and systems
 */
import { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { getServiceInstance, dataflowApiBase, systemApiBase } from '../service';
import { SYSTEMS } from '../constants';
import { useNotifyError } from '../hooks/useNotifyError';
import { useTenantState } from './user';
import { ERROR_TYPES } from 'components/ReportViewComponents/constants';

const connectionsFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${dataflowApiBase}${endpoint}`);
};

const systemsFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${systemApiBase}${endpoint}`);
};

/**
 * custom hook to fetch and keep track of connection and systems
 */
export function useConnectionsAndSystems() {
  const { tenantId } = useParams();

  const {
    data: connectionData,
    error: connectionError,
    isLoading: isConnectionsLoading,
    refetch: refetchConnections,
  } = useQuery({
    queryKey: ['/connection?populateUserSystem=true', tenantId],
    queryFn: connectionsFetcher,
    enabled: Boolean(tenantId),
  });

  const {
    data: sfSystemsData,
    error: sfSystemsError,
    isLoading: issfSystemsLoading,
    refetch: refetchSystems,
  } = useQuery({
    queryKey: [`/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`, tenantId],
    queryFn: systemsFetcher,
    enabled: Boolean(tenantId),
  });

  const {
    data: odataSystemsData,
    error: odataSystemsError,
    isLoading: isodataSystemsLoading,
  } = useQuery({
    queryKey: [`/user-system?systemCode=${SYSTEMS.ODATA_SERVICE.KEY}`, tenantId],
    queryFn: systemsFetcher,
    enabled: Boolean(tenantId),
  });

  const isSystemsLoading =
    (!sfSystemsError && !sfSystemsData) || (!odataSystemsError && !odataSystemsData);

  const error = sfSystemsError || odataSystemsError || connectionError;

  useNotifyError({ error, mustRedirect: true });

  return {
    error,
    sfSystems: sfSystemsData ?? [],
    odataSystems: odataSystemsData ?? [],
    connections: connectionData ?? [],
    isSystemsLoading: issfSystemsLoading || isodataSystemsLoading,
    isConnectionsLoading: isSystemsLoading || isConnectionsLoading,
    refetchConnections,
    refetchSystems,
  };
}

export function useConnection(selectedConnectionId) {
  const { connections, isConnectionsLoading } = useConnectionsAndSystems();

  const selectedConnection = useMemo(
    () => connections.find((connection) => connection.connection_id === selectedConnectionId),
    [connections, selectedConnectionId]
  );

  return { selectedConnection, isConnectionsLoading };
}

export function useUserSystem(selectedUserSystemId) {
  const { sfSystems, odataSystems } = useConnectionsAndSystems();

  const userSystem = useMemo(
    () =>
      [...sfSystems, ...odataSystems].find(
        ({ user_system_id }) => user_system_id === selectedUserSystemId
      ),
    [odataSystems, selectedUserSystemId, sfSystems]
  );

  return userSystem;
}

/**
 * This hook returns helper functions required to update the cache related to a connection
 *
 * @param {string} connectionId - Connection Id
 * @returns
 */
export function useConnectionHelper(connectionId) {
  const queryClient = useQueryClient();
  const tenant = useTenantState();
  const { selectedConnection: connection } = useConnection(connectionId);

  const [isArchiving, setIsArchiving] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [activationError, setActivationError] = useState(null);

  const updateConnection = useCallback(
    (newData) => {
      // Update the all jobs cache
      queryClient.setQueryData(
        ['/connection?populateUserSystem=true', tenant?.tenant_id],
        (oldConnections) => {
          // Find the current job index
          const connectionIndex = oldConnections.findIndex(
            (conn) => conn.connection_id === connectionId
          );

          // Only update the array if the job is found
          if (connectionIndex > -1) {
            return [
              ...oldConnections.slice(0, connectionIndex),
              {
                ...oldConnections[connectionIndex],
                ...newData,
              },
              ...oldConnections.slice(connectionIndex + 1),
            ];
          }

          return oldConnections;
        }
      );
    },
    [queryClient, connectionId, tenant?.tenant_id]
  );

  const clearConnectionCache = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        // TODO: @srinath59 - This is a temporary fix to clear the cache for the connection. Ideally, we should
        // invalidate the cache only for the specific connection and not the entire cache. To make this work, we
        // we need to ensure that all the react-query queries have the connectionId as part of the query key.
        queryClient.clear();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }, [queryClient]);

  // If the user, refreshes the connection - we want to invalidate the connection cache
  const refreshConnection = useCallback(async () => {
    toast.promise(
      clearConnectionCache(),
      {
        loading: 'Refreshing the connection...',
        success: 'Successfully refreshed the connection! 🎉',
        error: 'Failed to refresh the connection',
      },
      {
        position: 'top-center',
      }
    );
  }, [clearConnectionCache]);

  const updateArchiveState = useCallback(
    async (state) => {
      // If the connection is unarchived, we need to refresh the metadata so that the user can work with the
      // latest metadata
      if (!state) {
        refreshConnection();
      }

      try {
        setIsArchiving(true);
        const updatedConnectionInfo = await getServiceInstance(tenant?.tenant_id).put(
          `${dataflowApiBase}/connection/${connection.connection_id}/archive`,
          {
            is_archived: state,
          }
        );

        if (
          typeof updatedConnectionInfo === 'object' &&
          updatedConnectionInfo.hasOwnProperty('is_archived')
        ) {
          updateConnection(updatedConnectionInfo);
          setIsArchiving(false);
          return;
        }

        throw new Error();
      } catch {
        setIsArchiving(false);
        toast.error('Failed to archive connection. Please try again');
      }
    },
    [connection.connection_id, refreshConnection, tenant?.tenant_id, updateConnection]
  );

  const activateConnection = useCallback(async () => {
    setIsActivating(true);
    try {
      const activationInfo = await getServiceInstance(tenant?.tenant_id).post(
        `${dataflowApiBase}/connection/${connection?.connection_id}/activate`
      );
      if (activationInfo.type === ERROR_TYPES.VALIDATION_ERROR) {
        toast.error(activationError.errorDetails);
      }
      // If the activation failed, we need to throw the error so that it can be caught and handled properly
      if (activationInfo.error) {
        throw activationInfo.erorr;
      }
      // If the activation is successful, we need to invalidate the connection cache and refetch the latest data
      if (activationInfo.is_active) {
        queryClient.invalidateQueries(['/connection?populateUserSystem=true', tenant?.tenant_id]);
      }
      // Notify the users about the successful activation{}
      toast.success('Successfully activated the connection 🎉');
    } catch (error) {
      setActivationError(error);
    } finally {
      setIsActivating(false);
    }
  }, [activationError?.errorDetails, connection?.connection_id, queryClient, tenant?.tenant_id]);

  return {
    updateConnection,
    refreshConnection,
    updateArchiveState,
    isArchiving,
    activateConnection,
    isActivating,
    activationError,
  };
}
